@import '../../../scss/utils/variables';

.container {
    position: relative;
    width: 100%;
    height: 80vh;
    overflow: hidden;
    justify-content: center;
    color: #fff;
    // padding: 1rem;
    // margin-top: 15rem;

    @media (max-width: 1024px) {
        margin-top: 10rem;

      }
  
      @media (max-width: 600px) {
        margin-top: 5rem;

      }

    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
  }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.671); 
        z-index: 2;
    }

    .content {
        position: relative;
        z-index: 3;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;

        h1 {
            font-family: $font-family-header;
            font-weight: $font-weight-bold;
            color: $white;
            font-size: 70px;
            margin-bottom: 30px;
            padding: 0rem .5rem;
      
            text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.6);
          }
      
          
          @media (max-width: 1024px) {
            h1 {
              font-size: 48px;
            }
          }
      
          @media (max-width: 600px) {
            h1 {
              font-size: 32px;
            }
          }

          p {
            padding: 0rem .5rem;
            font-family: $font-family-header;
            font-weight: 200;
            color: white;
            font-size: 28px;
            margin-bottom: 10px;
      
            text-shadow: 10px 10px 10px rgb(0, 0, 0);
          }
      
          @media (max-width: 1024px) {
            p {
              font-size: 24px;
            }
          }
      
          @media (max-width: 600px) {
            p {
              font-size: 20px;
            }
          }

          a {
            margin-top: 2rem;
            display: inline-block;
            padding: 1rem 3.5rem;
            border: 0.16rem solid $white;
            color: $white;
            box-sizing: border-box;
            text-transform: uppercase;
            font-family: $font-family-header;
            font-size: 24px;
            font-weight: 400;
            text-align: center;
            transition: all 0.15s ease;
            cursor: pointer;
            position: relative;
            z-index: 2; // Ensures button is clickable
      
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
      
            &:hover {
              color: #dddddd;
              border-color: #dddddd;
            }
      
            &:active {
              color: #bbbbbb;
              border-color: #bbbbbb;
            }
          }
      
          @media (max-width: 1024px) {
            a {
              font-size: 18px;
              padding: .9rem 2.5rem;
            }
          }
      
          @media (max-width: 600px) {
            a {
              font-size: 16px;
              padding: .8rem 2rem;
            }
          }
    }
}