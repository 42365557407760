@import "../../../../scss/utils/variables";

.container {
  width: 80%;
  margin: 10rem auto;
  max-width: 1400px;

  @media (max-width: 768px) {
    margin: 5rem auto;
  }

  h2 {
    font-family: $font-family-header;
    font-size: $header-font-size;
    font-weight: $header-font-weight;
    color: $header-color;
    margin-bottom: $header-bottom-gap;

    @media (max-width: 768px) {
      text-align: left;
    }
  }

  h3 {
    font-family: $font-family-header;
    font-size: $subheader-font-size;
    font-weight: $subheader-font-weight;
    color: $blue-sub-header;
    margin-bottom: $subheader-bottom-gap;

    @media (max-width: 768px) {
      text-align: left;
    }
  }

  p {
    font-family: $font-family-header;
    font-size: $text-font-size;
    margin-bottom: $text-bottom-gap;
    color: #121214;
    width: 70%;

    @media (max-width: 768px) {
      text-align: left;
      width: 100%;
      margin: 0;
    }
  }

  .groupContainerBig {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr; // Three equal columns in the top rows
    grid-template-rows: auto auto 1fr; // Two auto rows, third row splits into two column

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }

    .leftGroup {
      // display: flex;
      align-items: flex-start;
      // gap: 1rem;
      padding: 2rem;
      // background: #f5f5f5;
      background-color: #f7f9fc;
      // border: solid 1px #f5f5f5;
      border-radius: 8px;
      transition: box-shadow 0.3s ease;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.062);

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        padding: 1rem;
      }

      &:hover {
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
      }

      .icon {
        width: 30px;
        margin-bottom: 1rem;
        border-radius: 10px;
        padding: 1rem;
        background: linear-gradient(90deg, $blue-header, #0079a1, #07587F, #0098ca); /* Vibrant gradient */
        animation: gradientBorderAnimation 6s linear infinite; /* Smooth gradient movement */
        mask-composite: exclude;
        // z-index: -1; 
        -webkit-mask-composite: destination-out;
        background-size: 400% 400%; /* Larger size for smooth animation */

        @media (max-width: 1024px) {
          width: 40px;

        }

        @media (max-width: 768px) {
          width: 35px;

        }
      }
      .groupHeader {
        text-align: left;
        font-family: $font-family-header;
        font-weight: 600;
        margin-bottom: 1rem;
        font-size: 16px;

        @media (max-width: 1024px) {
          font-size: 15px;

        }

        @media (max-width: 768px) {
          font-size: 14px;

        }
      }
      .groupText {
        text-align: left;
        font-family: $font-family-header;
        font-weight: 400;
        font-size: 14px;

        @media (max-width: 1024px) {
          font-size: 13px;
        }

        @media (max-width: 600px) {
          font-size: 13px;
        }
      }
    }

    /* Specific grid area placements */
    .item1 {
      grid-area: 1 / 1 / 2 / 3; // Row 1, Column 1 (Top Left)
    }
    .item3 {
      grid-area: 1 / 3 / 3 / 4; // Row 1 to 2, Column 3 (Top Right + Middle Right)
    }
    .item4 {
      grid-area: 2 / 1 / 3 / 3; // Row 2, Columns 1-2 (Middle Left + Center)
    }
    .item5 {
      grid-area: 3 / 1 / 4 / 2; // Row 3, Column 1 (Bottom Left)
    }
    .item6 {
      grid-area: 3 / 2 / 4 / 4; // Row 3, Column 3 (Bottom Right)
    }
  }
}

// Responsive styling
@media (max-width: 768px) {
  .groupContainer {
    flex-wrap: wrap; // Enable wrapping
  }
  .leftGroup {
    display: flex; // Keep displaying the regular leftGroup
  }

  .leftGroupSmall {
    display: flex; // Display leftGroupSmall on smaller screens
  }

  .rightGroup {
    display: none; // Hide rightGroup on smaller screens
  }
}

// Tablet styles
@media (max-width: 1024px) {
  .container h2 {
    font-size: $header-font-size-tablet;
    font-weight: $header-font-weight-tablet;
    margin-bottom: $header-bottom-gap-tablet;
  }

  .container h3 {
    font-size: $subheader-font-size-tablet;
    font-weight: $subheader-font-weight-tablet;
    margin-bottom: $subheader-bottom-gap-tablet;
  }

  .container p {
    font-size: $text-font-size-tablet;
    line-height: $text-line-height-tablet;
    margin-bottom: $text-bottom-gap-tablet;
  }
}

// Mobile styles
@media (max-width: 600px) {
  .container h2 {
    font-size: $header-font-size-mobile;
    font-weight: $header-font-weight-mobile;
    margin-bottom: $header-bottom-gap-mobile;
  }

  .container h3 {
    font-size: $subheader-font-size-mobile;
    font-weight: $subheader-font-weight-mobile;
    margin-bottom: $subheader-bottom-gap-mobile;
  }

  .container p {
    font-size: $text-font-size-mobile;
    line-height: $text-line-height-mobile;
    margin-bottom: $text-bottom-gap-mobile;
  }
}

@keyframes gradientBorderAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}