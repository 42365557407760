@import "../../../scss/utils/variables";

.backgroundImage {
  // background-image: url("../../../Assets/Hero3.png");
  // background-image: url("../../../Assets/Hero3dark.png");
  background-image: url("../../../Assets/Hero3darkUpscaled_optimized.webp");
  background-color: $blue-dark;
  // background-image: url("../../../Assets/alternateHero.png");
  background-size: cover;
  background-position: center center;
  // background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative; // Keep it relative to control layering
  height: 100vh;

  @media (min-width: 1024px) {
    background-attachment: fixed;
  }

  @media (max-width: 700px) {
    background-image: url("../../../Assets/mobileHero_optimized.webp");
    background-position: center center;
    background-size: cover;
  }

  // Filter the Image
  background-color: rgba(0, 0, 0, 0.2);
  background-blend-mode: darken;
  filter: contrast(1.15);

  // Center Overlay Text
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    align-items: start;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black; // Dark overlay color
    z-index: 1;
  }

  .overlayText {
    margin-top: 5rem;
    color: white;
    display: flex;
    flex-direction: column;
    z-index: 1; // Ensures it is above the background
    text-align: center;
    position: relative; // Ensures it layers correctly on top of backgroundImage

    @media (max-width: 700px) {
      margin-top: 0rem;
      height: 80%;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      padding: 7rem 2rem;
    }

    h1 {
      font-family: $font-family-header;
      font-weight: 200;
      color: $text-light;
      font-size: 28px;
      margin-bottom: 10px;

      text-shadow: 10px 10px 10px rgb(0, 0, 0);
    }

    @media (max-width: 1024px) {
      h1 {
        font-size: 24px;
      }
    }

    @media (max-width: 600px) {
      h1 {
        font-size: 20px;
        text-align: left;
        // padding: 0 2rem;
      }
    }

    h2 {
      font-family: $font-family-header;
      font-weight: $font-weight-bold;
      color: $white;
      font-size: 58px;
      margin-bottom: 30px;

      text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.6);
    }

    @media (max-width: 1024px) {
      h2 {
        font-size: 48px;
      }
    }

    @media (max-width: 600px) {
      h2 {
        font-size: 32px;
        text-align: left;
        // padding: 0 2rem;
      }
    }

    .buttonContainer {
      display: flex;
      gap: 1rem;
      justify-content: center;

      @media (max-width: 700px) {
        flex-direction: column;
        width: 80%;
        margin: 0 auto;
      }
    }

    .heroButton {
      display: inline-block;
      padding: 1rem 3.5rem;
      border: 0.16rem solid $white;
      color: $black;
      background-color: white;
      box-sizing: border-box;
      text-transform: uppercase;
      font-family: $font-family-header;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
      position: relative;
      z-index: 2; // Ensures button is clickable

      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);

      &:hover {
        color: #313131;
        border-color: #dddddd;
      }

      &:active {
        color: #bbbbbb;
        border-color: #bbbbbb;
      }
    }

    .heroButton2 {
      display: inline-block;
      padding: 1rem 3.5rem;
      border: 0.16rem solid $white;
      color: $white;
      box-sizing: border-box;
      text-transform: uppercase;
      font-family: $font-family-header;
      font-weight: 400;
      text-align: center;
      cursor: pointer;
      position: relative;
      z-index: 2; // Ensures button is clickable

      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);

      &:hover {
        color: #dddddd;
        border-color: #dddddd;
      }

      &:active {
        color: #bbbbbb;
        border-color: #bbbbbb;
      }
    }

    @media (max-width: 1024px) {
      .heroButton {
        font-size: 18px;
        padding: 0.9rem 2.5rem;
      }
    }

    @media (max-width: 1024px) {
      .heroButton2 {
        font-size: 18px;
        padding: 0.9rem 2.5rem;
      }
    }

    @media (max-width: 600px) {
      .heroButton {
        font-size: 16px;
        padding: 0.8rem 2rem;
      }
    }
    
    @media (max-width: 600px) {
      .heroButton2 {
        font-size: 16px;
        padding: 0.8rem 2rem;
      }
    }
  }
}


