@import '../../scss/utils/variables';

.container {
  width: 64%;
  margin: 5rem auto;
  max-width: 1200px;
  text-align: center;
  height: 1200px;

  h2 {
    font-family: $font-family-header;
    font-size: 32px;
    font-weight: 600;
    color: $blue-header;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-family: $font-family-header;
    font-size: 24px;
    font-weight: 400;
    color: $blue-sub-header;
    margin-bottom: 1rem;
  }

  p {
    font-family: $font-family-body;
    font-size: 16px;
    margin-bottom: 2rem;
  }
}

.circleContainer {
  position: relative;
  width: 400px; /* Adjusted container width */
  height: 600px; /* Adjusted container height for tall image */
  margin: 3rem auto;
}

.centerImage {
  position: absolute;
  top: 70%;
  left: 50%;
  width: 200px;
  height: 433px;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.contentSection {
  position: absolute;
  width: 120px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
  font-family: $font-family-header;
  font-size: 14px;
  color: #121214;

  img {
    width: 50px;
    height: 50px;
    margin-bottom: 0.5rem;
  }
}

/* Positioning content sections around the image */
.contentSection:nth-child(2) { /* Top Center */
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contentSection:nth-child(3) { /* Top-right */
  top: 45%;
  right: -200px;
  transform: translate(0, -50%);
}

.contentSection:nth-child(4) { /* Bottom-right */
  bottom: 05%;
  right: -200px;
  transform: translate(0, 50%);
}

.contentSection:nth-child(5) { /* Bottom-left */
  bottom: 05%;
  left: -200px;
  transform: translate(0, 50%);
}

.contentSection:nth-child(6) { /* Top-left */
  top: 45%;
  left: -200px;
  transform: translate(0, -50%);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .circleContainer {
    width: 100%;
    height: auto;
    margin-top: 2rem;
  }

  .contentSection {
    width: 80px;
    height: 60px;
  }

  .centerImage {
    width: 150px;
    height: 300px;
  }
}
