@import '../../../../scss/utils/variables';

.pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 8px;
  }
  
  .pageButton {
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 4px;
    background-color: #ddd;
    border: none;
    transition: 0.2s;
    font-size: 14px;
  }
  
  .pageButton:hover {
    background-color: #bbb;
  }
  
  .pageButton:disabled {
    background-color: #eee;
    cursor: not-allowed;
  }
  
  .active {
    background-color: #07587F;
    color: white;
    font-weight: bold;
    padding: 8px 12px;
    border-radius: 4px;
    border: none;
  }
  