@import "../../../../scss/utils/variables";

.solutionsContainer {
  width: 80%;
  max-width: 1400px;
  margin: 0 auto;
  height: 100%;
  color: white;
  padding-top: 10rem;
  padding-bottom: 5rem;

  @media (max-width: 600px){
    padding-top: 5rem;
  }

  .topRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .header {
      font-family: $font-family-header;
      font-size: $header-font-size;
      font-weight: $header-font-weight;
      color: $header-color;
      margin-bottom: $header-bottom-gap;
    }

    .subHeader {
      font-family: $font-family-header;
      font-size: $subheader-font-size;
      font-weight: $subheader-font-weight;
      color: $blue-sub-header;
      margin-bottom: $subheader-bottom-gap;
    }

    .dotContainer {
      display: flex;
      gap: 1rem; /* Space between dots */
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
    }

    .dot {
        width: 10px;
        height: 10px;
        background-color: #ccc;
        border-radius: 50%;
        cursor: pointer;
    }

    /* Active dot styling */
    .dot.active {
        width: 10px; /* Make it an oval */
        height: 10px;
        border: 2px solid $blue-header; /* Dark blue border */
        background-color: #ffffff; /* Optional: change background color */
      }
  }

  hr {
    margin: 1rem 0;
  }

  .orderNow {
    display: inline-block;
    width: 100%;
    padding: 1rem 2rem;
    border-radius: 100px;
    color: $black;
    box-sizing: border-box;
    text-transform: uppercase;
    font-family: $font-family-header;
    font-weight: 400;
    text-align: center;
    transition: all 0.15s;
    cursor: pointer;
    font-size: 16px;
    z-index: 1; /* Ensures content is above the gradient border */
    position: relative; /* Required for the pseudo-element */
    overflow: hidden; /* Ensures the border stays within the card boundaries */
    margin-bottom: 1rem;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 100px; /* Matches the card's border radius */
      padding: 2px; /* Border thickness */
      background: linear-gradient(45deg, #ffffff, #00BFFF, #07587F, #00BFFF); /* Vibrant gradient */
      background-size: 400% 400%; /* Larger size for smooth animation */
      animation: gradientBorderAnimation 4s linear infinite; /* Smooth gradient movement */
      -webkit-mask: linear-gradient(white, white) content-box, linear-gradient(white, white);
      mask: linear-gradient(white, white) content-box, linear-gradient(white, white);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
      z-index: -1; /* Ensures the gradient stays behind the content */
    }

    &:hover {
      color: $blue-header
    }
  }
  .solutionsCardContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 2rem;

    @media (max-width: 800px) {
      flex-direction: column;
    }

    .solutionsCard {
      flex: 1;
      color: black;
      padding: 2.5rem;
      border-radius: 10px;
      position: relative; /* Required for the pseudo-element */
      overflow: hidden; /* Ensures the border stays within the card boundaries */
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
      z-index: 1; /* Ensures content is above the gradient border */
      width: 100%;

      @media (max-width: 800px) {
        flex-direction: column;
        width: 90%;
        margin: 0 auto;
      }
  
    
      /* Gradient border with animation */
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 10px; /* Matches the card's border radius */
        padding: 1px; /* Border thickness */
        background: linear-gradient(45deg, #ffffff, #00BFFF, #07587F, #00BFFF); /* Vibrant gradient */
        background-size: 400% 400%; /* Larger size for smooth animation */
        animation: gradientBorderAnimation 4s linear infinite; /* Smooth gradient movement */
        -webkit-mask: linear-gradient(white, white) content-box, linear-gradient(white, white);
        mask: linear-gradient(white, white) content-box, linear-gradient(white, white);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
        z-index: -1; /* Ensures the gradient stays behind the content */
      }
    
      &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Slight shadow on hover for better effect */
      }

      .solutionsCardTopRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;

        .solutionsCardTitle {
          font-family: $font-family-header;
          font-size: 20px;
          font-weight: 400;
        }

        @media (max-width: 1024px) {
          .solutionsCardTitle {
            font-size: 18px;
          }
        }

        @media (max-width: 600px) {
          .solutionsCardTitle {
            font-size: 15px;
          }
        }

        .solutionsCardInstantDeploy {
          font-family: $font-family-header;
          font-size: 11px;
          font-weight: 600;
          color: black;
          text-align: center;
          background-color: white;
          padding: 5px 10px;
          border-radius: 100px;
        }

        @media (max-width: 1024px) {
          .solutionsCardInstantDeploy {
            font-size: 10px;
          }
        }

        @media (max-width: 600px) {
          .solutionsCardInstantDeploy {
            font-size: 9px;
          }
        }
      }

      .solutionsCardPrice {
        font-family: $font-family-header;
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 1.5rem;
      }

      @media (max-width: 1024px) {
        .solutionsCardPrice {
          font-size: 35px;
        }
      }

      @media (max-width: 600px) {
        .solutionsCardPrice {
          font-size: 25px;
        }
      }

      hr {
        position: relative;
        height: 1.5px; /* Adjust thickness */
        border: none; /* Remove default border */
        background: none; /* Remove default background */
        margin-bottom: 1rem;
        overflow: hidden;
      
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(90deg, #ffffff, #00BFFF, #07587F, #00BFFF);
          background-size: 200% 200%; /* Larger gradient for smooth animation */
          animation: gradientAnimation 4s linear infinite; /* Continuous animation */
        }
      }
      
      /* Keyframes for gradient animation */
      @keyframes gradientAnimation {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }
      

      .solutionsCardBottomRow {
        display: flex;
        gap: 3rem;
        justify-content: space-between;
        align-items: flex-end;

        .solutionsCardIncludes {
          text-transform: uppercase;
          font-family: $font-family-body;
          font-size: 14px;
          font-weight: bold;
        }

        @media (max-width: 1024px) {
          .solutionsCardIncludes {
            font-size: 13px;
          }
        }
  
        @media (max-width: 600px) {
          .solutionsCardIncludes {
            font-size: 12px;
          }
        }

        .solutionsList {
          list-style-type: none;
          padding: 0;
          margin: 1rem 0 0 0;

          li {
            position: relative;
            padding-left: 30px;
            background: url("../../../../Assets/icons/Check-Blue.svg") no-repeat
              left center; /* Use the SVG */
            background-size: 20px 20px;
            margin-bottom: 10px;
            font-family: $font-family-header;
            font-size: 16px;

            &:before {
              content: "";
            }
          }

          @media (max-width: 1024px) {
            li {
              font-size: 15px;
              background-size: 18px 18px;
            }
          }
    
          @media (max-width: 600px) {
            li {
              font-size: 12px;
              background-size: 16px 16px;

            }
          }
        }


        @media (max-width: 1024px) {
          .orderNow {
            font-size: 14px;
          }
        }
  
        @media (max-width: 600px) {
          .orderNow {
            font-size: 12px;
            padding: 0.4rem 1.5rem;
          }
        }

        a:hover {
          color: $blue-header;
          border-color: $blue-header;
          cursor: pointer;
        }

        a:active {
          color: $blue-header;
          border-color: $blue-header;
        }
      }
    }

    @media (max-width: 600px) {
      .solutionsCard {
        padding: 1.5rem;
      }
    }
  }
}



    // Tablet styles
    @media (max-width: 1024px) {
      .solutionsContainer .topRow .header {
        font-size: $header-font-size-tablet;
        font-weight: $header-font-weight-tablet;
        margin-bottom: $header-bottom-gap-tablet;
      }
  
      .solutionsContainer .topRow .subHeader  {
        font-size: $subheader-font-size-tablet;
        font-weight: $subheader-font-weight-tablet ;
        margin-bottom: $subheader-bottom-gap-tablet;
      }
  
      .solutionsContainer .text {
        font-size: $text-font-size-tablet;
        line-height: $text-line-height-tablet;
        margin-bottom: $text-bottom-gap-tablet;
      }
    }
  
    // Mobile styles
    @media (max-width: 600px) {
      .solutionsContainer .topRow .header {
        font-size: $header-font-size-mobile ;
        font-weight: $header-font-weight-mobile;
        margin-bottom: $header-bottom-gap-mobile;
      }
  
      .solutionsContainer .topRow .subHeader {
        font-size: $subheader-font-size-mobile;
        font-weight: $subheader-font-weight-mobile;
        margin-bottom: $subheader-bottom-gap-mobile;
      }
  
      .solutionsContainer  .text {
        font-size: $text-font-size-mobile;
        line-height: $text-line-height-mobile;
        margin-bottom: $text-bottom-gap-mobile;
      }
    }

    /* Keyframes for the gradient animation */
@keyframes gradientBorderAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}