@import "../../../../scss/utils/variables";

.background {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 26, 51, 1),
      rgba(0, 0, 0, 0.92)
    ),
    /* Gradient with opacity */
      url("../../../../Assets/colocationBackground1.png"); /* Background image */
  background-position: center center; /* Center the image */
  background-size: cover; /* Cover the viewport */
  background-repeat: no-repeat; /* No repeat */
  width: 100%; /* Full width */

  .allContentContainer {
    padding: 4rem 1rem;
  }

  .dedicatedServersContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 2rem;
    max-width: 1200px;
    padding: 0 1rem;
    flex-wrap: wrap;
    color: white;
    text-align: left;

    @media (max-width: 800px) {
      flex-direction: column;
      text-align: center;
    }

    .dedicatedServersLeft {
      flex: 1;

      .dedicatedServersHeader {
        font-family: "Montserrat", sans-serif;
        font-size: 38px;
        font-weight: 800;
        color: white;
        margin-bottom: 1rem;
        text-align: left;

        @media (max-width: 800px) {
          text-align: center;
        }
      }

      .dedicatedServersSubHeader {
        font-family: $font-family-header;
        font-size: 1.5rem;
        font-weight: 600;
        color: $white;
        margin-bottom: 1rem;
      }

      .dedicatedServersText {
        font-family: $font-family-header;
        font-size: 1rem;
        color: white;
        // margin: 0 auto;
        margin-bottom: 1.5rem;
        line-height: 1.6;
        max-width: 50%;

        @media (max-width: 800px) {
          margin: 0 auto;
          max-width: 80%;
          margin-bottom: 1rem;
        }
      }

      .button {
        display: inline-block;
        padding: 1rem 3.5rem;
        border: 0.16rem solid $blue-sub-header;
        color: $blue-sub-header;
        font-family: $font-family-header;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        transition: all 0.3s ease;
        cursor: pointer;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);

        &:hover {
          color: lighten($blue-sub-header, 20%);
          border-color: lighten($blue-sub-header, 20%);
        }

        &:active {
          color: lighten($blue-sub-header, 35%);
          border-color: lighten($blue-sub-header, 35%);
        }
      }
    }

    .imageContainer {
      flex: 1;
      display: flex;
      justify-content: center;

      .standardServer {
        height: 450px;
        width: auto;
        border-radius: 10px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);

        @media (max-width: 1024px) {
          height: 350px;
        }

        @media (max-width: 700px) {
          height: 300px;
        }
      }
    }
  }

  .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem 0rem;
    color: white;
    position: relative; /* Required for the pseudo-element */
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;

    @media (max-width: 849px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    /* Remove the red border and add the gradient border */
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 15px; /* Add rounded corners to match */
      padding: 1.5px; /* Thickness of the border */
      background: linear-gradient(
        45deg,
        #07587f,
        #00bfff,
        #107b9a,
        #90b4d1,
        #07587f
      ); /* Vibrant gradient */
      background-size: 400% 400%; /* Larger size for smooth animation */
      animation: gradientBorderAnimation 4s linear infinite; /* Smooth animation */
      z-index: -1; /* Ensure the gradient stays behind content */

      /* Mask the content inside */
      -webkit-mask: linear-gradient(white, white) content-box,
        linear-gradient(white, white);
      mask: linear-gradient(white, white) content-box,
        linear-gradient(white, white);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }

    background: rgba(124, 124, 124, 0.068); /* Inner background color */
    border-radius: 15px; /* Match border radius */
    z-index: 1; /* Ensure content stays above the gradient border */

    .colocationSpecs {
      display: flex;
      .header {
        text-align: center;
        font-size: 2rem;
        margin-bottom: 2rem;
      }

      .info {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0rem 1rem;

        @media (max-width: 849px) {
          width: 100%;
          margin-bottom: 3rem;
        }

        .faq {
          background: #0d3c56; /* Transparent background */
          border-radius: 15px;
          overflow: hidden; /* Prevent content overflow */
          width: 100%; /* Consistent width in all states */
          transition: all 0.3s ease;
          max-height: 60px; /* Initial height when closed */
          cursor: pointer;

          @media (max-width: 849px) {
            width: 100%; /* Consistent width in all states */
          }

          &.expanded {
            max-height: 500px; /* Set a high enough value for expanded state */
            padding-bottom: 1rem; /* Add padding at the bottom for expanded content */
          }

          .faqHeader {
            display: flex;
            justify-content: space-between; /* Push left and right elements to edges */
            align-items: center; /* Center items vertically */
            padding: 1rem 1.5rem; /* Add padding around the header */

            .leftContainer {
              display: flex;
              align-items: center; /* Align icon and title vertically */
              gap: 0.5rem;

              .icon {
                color: white;
              }

              .faqTitle {
                color: white;
                font-family: "Montserrat", sans-serif;
                color: white;
                font-weight: 700;
                font-size: 20px;
              }
            }

            .arrowIndicator {
              color: white;
              font-size: 1.25rem;
              transition: transform 0.3s ease;
            }

            .arrowOpen {
              transform: rotate(180deg);
            }
          }

          .faqContent {
            list-style: none;
            padding: 0 1.5rem 1rem; /* Match header padding and add bottom padding */
            margin: 0;
            opacity: 0; /* Start hidden */
            max-height: 0; /* Prevent content from showing */
            transition: max-height 0.3s ease, opacity 0.3s ease;

            .listItem {
              display: flex;
              align-items: center;
              gap: 1rem;
              color: white;
              font-family: "Montserrat", sans-serif;
              font-size: 15px;
              font-weight: 600;
              margin-top: 1.5rem;

              .checkIcon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1.5rem;
                height: 1.5rem;
                // border: 2px solid white;
                border-radius: 50%;
                font-size: 0.9rem;
                color: white;
              }
            }
          }

          &.expanded .faqContent {
            max-height: 515px; /* Enough to fit all content */
            opacity: 1; /* Fade in the content */
          }
        }
      }
    }

    .imageTour {
      padding: 0rem 1rem;

      .imageCaption {
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
        font-weight: 600;
        margin-top: 1.5rem;
        text-align: center;

        @media (max-width: 600px) {
          width: 80%; /* Consistent width in all states */
          margin: 0 auto;
          margin-top: 1.5rem;
        }
      }
    }
  }
}

/* Gradient border animation */
@keyframes gradientBorderAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.selectorContainerContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  margin-bottom: 2rem;

  .selectorContainer {
    position: relative;
    border-radius: 10px;
    display: flex;
    color: white;
    text-align: center;
    justify-content: space-around;
    align-items: stretch;
    z-index: 0; /* Ensure proper stacking context */
    overflow: hidden;

    /* Animated gradient border */
  }

  /* Ensure consistent spacing and child styles */
  .ogden,
  .losangeles {
    width: 100%;
    padding: 1rem 0;
    text-align: center;
    cursor: pointer;
    position: relative;
    background-color: rgba(255, 255, 255, 0.1); // Slightly lighter background

    &.active {
      background-color: rgba(255, 255, 255, 0.1); // Slightly lighter background

      /* Add animated underline */
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 10%; /* Adjust as needed for spacing */
        width: 80%; /* Adjust width of underline */
        height: 3px; /* Thickness of the underline */
        background: linear-gradient(
          45deg,
          #07587f,
          #00bfff,
          #107b9a,
          #90b4d1,
          #07587f
        ); /* Vibrant gradient */
        background-size: 400% 400%; /* Larger size for animation */
        animation: gradientBorderAnimation 4s linear infinite; /* Reuse animation */
        border-radius: 10px; /* Smooth edges */
      }
    }
  }

  .ogden {
    border-radius: 10px 0 0 10px; /* Match parent container's radius */
    flex-grow: 1;
  }
}

      .imgContainer {
        padding: 1rem;
        img {
          width: clamp(350px, 60vw, 700px); /* Scales between 300px (min), 50% of viewport width, and 700px (max) */
          height: clamp(200px, 30vw, 450px); /* Scales between 200px (min), 30% of viewport width, and 450px (max) */
          overflow: hidden;
          position: relative;
        
          @media (max-width: 400px) {
            width: clamp(300px, 55vw, 600px);
          }
        }
      }

      .here {
        color: white;
        text-decoration: underline;
        font-weight: bold;
      }
