@import "../../../../scss/utils/variables";

.background {
  position: relative;
  background: linear-gradient(
    180deg,
    #000307,
    #001a33,
  ); /* Your main background */
  padding: 5rem 0;
  overflow: hidden;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;

    .header {
      font-family: $font-family-header;
      font-size: 38px;
      font-weight: 800;
      color: $white;
      margin-bottom: 1rem;
      text-align: center;
    }

    .selectorContainer {
      position: relative;
      border-radius: 10px;
      display: flex;
      color: white;
      text-align: center;
      justify-content: space-around;
      align-items: stretch;
      z-index: 0; /* Ensure proper stacking context */
      overflow: hidden;
    
      /* Animated gradient border */

    }
    
    /* Ensure consistent spacing and child styles */
    .ogden,
    .losangeles {
      width: 100%;
      padding: 1rem 0;
      text-align: center;
      cursor: pointer;
      position: relative;
      background-color: rgba(255, 255, 255, 0.1); // Slightly lighter background
    
      &.active {
        background-color: rgba(255, 255, 255, 0.1); // Slightly lighter background
    
        /* Add animated underline */
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 10%; /* Adjust as needed for spacing */
          width: 80%; /* Adjust width of underline */
          height: 3px; /* Thickness of the underline */
          background: linear-gradient(
            45deg,
            #07587f,
            #00bfff,
            #107b9a,
            #90b4d1,
            #07587f
          ); /* Vibrant gradient */
          background-size: 400% 400%; /* Larger size for animation */
          animation: gradientBorderAnimation 4s linear infinite; /* Reuse animation */
          border-radius: 10px; /* Smooth edges */
        }
      }
    }
    
    
    .ogden {
      border-radius: 10px 0 0 10px; /* Match parent container's radius */
      flex-grow: 1;
    }
    

    
    
    

    .cardGrid {
      display: grid;
      grid-template-columns: repeat(
        auto-fit,
        minmax(400px, 1fr)
      ); /* Dynamically fill rows */
      gap: 1rem; /* Space between cards */

      @media (max-width:450px) {
        grid-template-columns: repeat(
          auto-fit,
          minmax(300px, 1fr)
        ); /* Dynamically fill rows */
      }
      
      .solutionsCardContainer {
        .solutionsCard {
          flex: 1;
          color: black;
          background-color: $blue-header;
          padding: 2.5rem;
          border-radius: 15px;
          position: relative;
          z-index: 0;
          overflow: hidden;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 15px;
            padding: 1.5px; /* Slightly thicker border */
            background: linear-gradient(
              45deg,
              #07587f,
              #00bfff,
              #107b9a,
              #90b4d1,
              #07587f
            ); /* Vibrant gradient */
            background-size: 400% 400%; /* Larger size for more movement */
            animation: gradientBorderAnimation 4s linear infinite; /* Faster animation */
            -webkit-mask: linear-gradient(white, white) content-box,
              linear-gradient(white, white);
            mask: linear-gradient(white, white) content-box,
              linear-gradient(white, white);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
            z-index: -1;
          }

          .solutionsCardTopRow {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1.5rem;

            .solutionsCardTitle {
              font-family: $font-family-header;
              color: white;
              font-weight: 700;
              font-size: 18px;
            }

            @media (max-width: 1024px) {
              .solutionsCardTitle {
                font-size: 18px;
              }
            }

            @media (max-width: 600px) {
              .solutionsCardTitle {
                font-size: 15px;
              }
            }

            .solutionsCardInstantDeploy {
              font-family: $font-family-header;
              font-size: 10px;
              font-weight: 600;
              color: black;
              text-align: center;
              background: linear-gradient(
                45deg,
                #00bfff,
                #2ca0da,
                #00bfff
              ); /* Vibrant gradient */
              padding: 10px 15px;
              border-radius: 5px;
            }

            @media (max-width: 1024px) {
              .solutionsCardInstantDeploy {
                font-size: 10px;
              }
            }

            @media (max-width: 600px) {
              .solutionsCardInstantDeploy {
                font-size: 9px;
              }
            }
          }

          .solutionsCardPrice {
            font-family: $font-family-header;
            font-size: 35px;
            font-weight: 600;
            margin-bottom: 1.5rem;
            color: white;
          }

          @media (max-width: 1024px) {
            .solutionsCardPrice {
              font-size: 35px;
            }
          }

          @media (max-width: 600px) {
            .solutionsCardPrice {
              font-size: 25px;
            }
          }

          hr {
            margin-bottom: 0rem;
          }

          .solutionsCardBottomRow {
            display: flex;
            gap: 3rem;
            justify-content: space-between;
            align-items: flex-end;

            .solutionsCardIncludes {
              text-transform: uppercase;
              font-family: $font-family-body;
              font-size: 13px;
              font-weight: 500;
              color: white;
            }

            @media (max-width: 1024px) {
              .solutionsCardIncludes {
                font-size: 13px;
              }
            }

            @media (max-width: 600px) {
              .solutionsCardIncludes {
                font-size: 12px;
              }
            }

            .solutionsList {
              list-style-type: none;
              padding: 0;
              margin: 1.5rem 0 0 0;

              li {
                position: relative;
                padding-left: 30px;
                background: url("../../../../Assets/icons/Check-White-Small.svg")
                  no-repeat left center; /* Use the SVG */
                background-size: 18px 18px;
                margin-bottom: 10px;
                font-family: $font-family-header;
                font-size: 15px;
                font-weight: 600;
                margin-top: 1.5rem;
                color: white;

                &:before {
                  content: "";
                }
              }

              @media (max-width: 1024px) {
                li {
                  font-size: 15px;
                  background-size: 18px 18px;
                }
              }

              @media (max-width: 600px) {
                li {
                  font-size: 12px;
                  background-size: 16px 16px;
                }
              }
            }
          }
          .orderNow {
            display: inline-block;
            margin: 2rem 0 3rem 0;
            padding: 1.25rem 2rem;
            color: black;
            background-color: white;
            font-weight: 600;
            border: none; /* Remove default border */
            border-radius: 100px;
            position: relative; /* Required for ::before pseudo-element */
            box-sizing: border-box;
            text-transform: uppercase;
            font-family: $font-family-header;
            text-align: center;
            transition: all 0.15s;
            cursor: pointer;
            font-size: 14px;
            width: 100%;
            z-index: 1; /* Ensure content stays above the border */

            &:hover {
              color: $blue-header;
            }

            /* Animated gradient border */
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border-radius: 100px; /* Matches the button's border radius */
              padding: 1px; /* Border thickness */
              background: linear-gradient(
                45deg,
                #ffffff,
                #00bfff
              ); /* Vibrant gradient */
              background-size: 400% 400%; /* Larger size for more movement */
              animation: gradientBorderAnimation 4s linear infinite; /* Smooth continuous animation */
              -webkit-mask: linear-gradient(white, white) content-box,
                linear-gradient(white, white);
              mask: linear-gradient(white, white) content-box,
                linear-gradient(white, white);
              -webkit-mask-composite: destination-out;
              mask-composite: exclude;
              z-index: -1; /* Ensures the border stays behind the button content */
            }
          }

          @media (max-width: 1100px) {
            .orderNow {
              font-size: 14px;
            }
          }

          @media (max-width: 600px) {
            .orderNow {
              font-size: 12px;
              padding: 1rem 1.5rem;
            }
          }

          a:hover {
            color: $blue-header;
            border-color: $blue-header;
            cursor: pointer;
          }

          a:active {
            color: $blue-header;
            border-color: $blue-header;
          }
        }

        @media (max-width: 600px) {
          .solutionsCard {
            padding: 1.5rem;
          }
        }
      }
    }
  }
}

/* Keyframes for Gradient Animataion */
@keyframes gradientBorderAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.customQuoteContainer {
  margin-top: 3rem;
  padding: 2rem 0;
  flex: 1;
  color: black;
  background-color: $blue-header;
  padding: 2.5rem;
  border-radius: 15px;
  position: relative;
  z-index: 0;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    padding: 1.5px; /* Slightly thicker border */
    background: linear-gradient(
      45deg,
      #07587f,
      #00bfff,
      #107b9a,
      #90b4d1,
      #07587f
    ); /* Vibrant gradient */
    background-size: 400% 400%; /* Larger size for more movement */
    animation: gradientBorderAnimation 4s linear infinite; /* Faster animation */
    -webkit-mask: linear-gradient(white, white) content-box,
      linear-gradient(white, white);
    mask: linear-gradient(white, white) content-box,
      linear-gradient(white, white);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    z-index: -1;
  }

  .customHeader {
    font-family: $font-family-header;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    background: linear-gradient(
      90deg,
      #ffffff,
      #00bef8,
      #d7dcdd,
      #fcfcfc,
      #80d2fc
    );
    background-size: 200% auto; /* Allows the gradient to move */
    color: transparent; /* Makes the text transparent */
    background-clip: text;
    -webkit-background-clip: text; /* Ensures compatibility with Webkit browsers */
    animation: gradientTextAnimation 5s linear infinite; /* Animation for the gradient */
    text-transform: uppercase;
  }

  .customColocationText {
    font-family: $font-family-header;
    font-size: 18px;
    font-weight: 600;
    margin-top: 1.5rem;
    color: white;
    text-align: center;
  }

  .getCustomOrderButton {
    display: inline-block;
    margin: 2rem 0 3rem 0;
    padding: 1.25rem 2rem;
    color: black;
    background-color: white;
    font-weight: 600;
    border: none; /* Remove default border */
    border-radius: 100px;
    position: relative; /* Required for ::before pseudo-element */
    box-sizing: border-box;
    text-transform: uppercase;
    font-family: $font-family-header;
    text-align: center;
    transition: all 0.15s;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    z-index: 1; /* Ensure content stays above the border */

    &:hover {
      color: $blue-header;
    }

    /* Animated gradient border */
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 100px; /* Matches the button's border radius */
      padding: 2.5px; /* Border thickness */
      background: linear-gradient(
        45deg,
        #ffffff,
        #00bfff
      ); /* Vibrant gradient */
      background-size: 400% 400%; /* Larger size for more movement */
      animation: gradientBorderAnimation 4s linear infinite; /* Smooth continuous animation */
      -webkit-mask: linear-gradient(white, white) content-box,
        linear-gradient(white, white);
      mask: linear-gradient(white, white) content-box,
        linear-gradient(white, white);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
      z-index: -1; /* Ensures the border stays behind the button content */
    }
  }
}

/* Keyframes for gradient animation */
@keyframes gradientTextAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#getcoloquote {
  scroll-margin-top: 5rem;
}
