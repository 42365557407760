@import '../../../../scss/utils/variables';

.container {
    width: 80%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 10rem 0;

    @media (max-width: 768px) {
        padding: 5rem 0;
    }
    

    .topSection {
        text-align: center;

        @media (max-width: 768px) {
            text-align: left;
        }

        .dedicatedServersHeader {
            font-family: $font-family-header;
            font-size: $header-font-size;
            font-weight: $header-font-weight;
            color: $header-color;
            margin-bottom: $header-bottom-gap;
            text-align: center;

            
        @media (max-width: 768px) {
            text-align: left;
        }
        }

        .dedicatedServersSubHeader {
            font-family: $font-family-header;
            font-size: $subheader-font-size;
            font-weight: $subheader-font-weight;
            color: $blue-sub-header;
            margin-bottom: $subheader-bottom-gap;
            text-align: center;

            
        @media (max-width: 768px) {
            text-align: left;
        }
        }

        .dedicatedServersText {
            width: 70%;
            margin: 0 auto;
            font-family: $header-font-family;
            font-size: $text-font-size;
            color: #121214;
            margin-bottom: $text-bottom-gap;

            @media (max-width: 768px) {
                width: 100%;
            }
    
        }
    }

    // Adjusted cardContainer for flexbox layout
    .cardContainer {
        display: flex;              
        flex-wrap: wrap;            
        justify-content: center;   
        margin-top: 4rem;
        gap: 1rem;

        @media (max-width: 768px) {
            justify-content: start;
            // width: 80%;
        }

        .card {
            flex: 1;
            background-color: #0D3C56;
            color: white;
            padding: 1.5rem 1.5rem;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            margin: 1rem 0rem;       
            min-width: 200px;    

            .cardTopRow {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                
                h3 {
                    font-family: $font-family-header;
                    font-weight: 300;   
                }

                .networkIcon {
                }
            }

            .circleAndState {
                margin-top: 2rem;
            }

            a {
                display: inline-block;
                padding: 0.8rem 3rem;
                border: 0.16rem solid $white;
                color: $white;
                box-sizing: border-box;
                text-transform: uppercase;
                font-family: $font-family-header;
                font-weight: 400;
                text-align: center;
                transition: all 0.15s;
            }

            a:hover {
                color: #DDDDDD;
                border-color: #DDDDDD;
            }

            a:active {
                color: #BBBBBB;
                border-color: #BBBBBB;
            }
        }
    }
}


// Tablet styles
@media (max-width: 1024px) {
    .container .topSection .dedicatedServersHeader {
      font-size: $header-font-size-tablet;
      font-weight: $header-font-weight-tablet;
      margin-bottom: $header-bottom-gap-tablet;
    }
  
    .container .topSection .dedicatedServersSubHeader {
      font-size: $subheader-font-size-tablet;
      font-weight: $subheader-font-weight-tablet;
      margin-bottom: $subheader-bottom-gap-tablet;
    }
  
    .container .topSection .dedicatedServersText {
      font-size: $text-font-size-tablet;
      line-height: $text-line-height-tablet;
      margin-bottom: $text-bottom-gap-tablet;
    }
  
  
  }
  
  // Mobile styles
  @media (max-width: 600px) {
    .container .topSection .dedicatedServersHeader {
      font-size: $header-font-size-mobile;
      font-weight: $header-font-weight-mobile;
      margin-bottom: $header-bottom-gap-mobile;
    }
    .container .topSection .dedicatedServersSubHeader {
      font-size: $subheader-font-size-mobile;
      font-weight: $subheader-font-weight-mobile;
      margin-bottom: $subheader-bottom-gap-mobile;
    }
  
    .container .topSection .dedicatedServersText {
      font-size: $text-font-size-mobile;
      line-height: $text-line-height-mobile;
      margin-bottom: $text-bottom-gap-mobile;
    }
  
  }