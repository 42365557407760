@import "../../../../scss/utils/variables";

.background {
  width: 100%;
  background-color: #f7f9fc;
  // background-color: #0d3c56;
  background-image: linear-gradient(160deg, #f7f9fc 100%, #a3cbe031 10%);
  padding-bottom: 5rem;

  @media (max-width: 600px){
    padding-bottom: 0rem;
  }
}

.servicesHeaderContainer {
    width: 80%;
    max-width: 1400px;
    margin: 0 auto;
    height: 100%;
    color: white;
    padding-bottom: 2.5rem;
    padding-top: 10rem;

    @media (max-width: 600px){
      padding-top: 5rem;
    }
  
    .header {
      font-family: $font-family-header;
      font-size: $header-font-size;
      font-weight: $header-font-weight;
      color: $header-color;
      margin-bottom: $header-bottom-gap;
    }

  
    .subHeader {
      font-family: $font-family-header;
      font-size: $subheader-font-size;
      font-weight: $subheader-font-weight;
      color: $blue-sub-header;
      margin-bottom: $subheader-bottom-gap;
    }
  
    .text {
      font-family: $font-family-header;
      font-size: $text-font-size;
      margin-bottom: $text-bottom-gap;
      color: black;
      line-height: 1.3;
      max-width: 70%;

      @media (max-width: 1024px){
        max-width: 80%;
    }

    @media (max-width: 600px){
        max-width: 100%;
    }
  
      span {
        font-weight: 600;
      }
    }
  
    hr {
      margin: 1rem 0;
    }
  }

.servicesContainer {
  width: 80%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 2.5rem;

  .cardContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 2rem; 

    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr); 
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr; 
    }

    .card {
      color: black;
      align-self: stretch;
      border-radius: 8px;
      padding: 4rem 2rem;
      background-color: white;
      text-align: left;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.027);
      display: flex;
      flex-direction: column;
      height: 65%;


      .cardTopRow {
        display: flex;
        align-items: flex-end;
        gap: 2rem;
        margin-bottom: 2rem;

        .image {
          max-width: 70px;
          margin: 0;
          padding: 0;
        }

        .cardHeaderContainer {
          .cardHeader {
            font-family: $font-family-header;
            font-size: 32px;
            font-weight: 600;
            color: $blue-header;
            margin-bottom: 0.5rem;
          }

          .cardSubHeader {
            font-family: $font-family-header;
            font-size: 24px;
            font-weight: 400;
            color: $blue-sub-header;
            margin-bottom: 1rem;
          }
        }
      }

      .cardText {
        font-family: $font-family-body;
        font-size: 16px;
        margin-bottom: 2rem;
        width: 90%;
      }

      .cardLearnMore {
        font-family: $font-family-header;
        font-size: 15px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
        margin-top: auto;
        transition: color 0.3s;

        &:hover {
          color: lighten($blue-header, 10%); // Optional: color change on hover
        }

        .arrowIcon {
          width: 16px;
          height: 16px;
          color: black;
        }
      }
    }
  }
}


    // Tablet styles
    @media (max-width: 1024px) {
        .servicesHeaderContainer .header,
        .servicesContainer .cardContainer .card .cardTopRow .cardHeaderContainer .cardHeader {
          font-size: $header-font-size-tablet;
          font-weight: $header-font-weight-tablet;
          margin-bottom: $header-bottom-gap-tablet;
        }
    
        .servicesHeaderContainer .subHeader,
        .servicesContainer .cardContainer .card .cardTopRow .cardHeaderContainer .cardSubHeader {
          font-size: $subheader-font-size-tablet;
          font-weight: $subheader-font-weight-tablet;
          margin-bottom: $subheader-bottom-gap-tablet;
        }
    
        .servicesHeaderContainer .text,
        .servicesContainer .cardContainer .card .cardText {
          font-size: $text-font-size-tablet;
          line-height: $text-line-height-tablet;
          margin-bottom: $text-bottom-gap-tablet;
        }
      }
    
      // Mobile styles
      @media (max-width: 600px) {
        .servicesHeaderContainer .header,
        .servicesContainer .cardContainer .card .cardTopRow .cardHeaderContainer .cardHeader {
          font-size: $header-font-size-mobile ;
          font-weight: $header-font-weight-mobile;
          margin-bottom: $header-bottom-gap-mobile;
        }
    
        .servicesHeaderContainer .subHeader,
        .servicesContainer .cardContainer .card .cardTopRow .cardHeaderContainer .cardSubHeader  {
          font-size: $subheader-font-size-mobile;
          font-weight: $subheader-font-weight-mobile;
          margin-bottom: $subheader-bottom-gap-mobile;
        }
    
        .servicesHeaderContainer  .text,
        .servicesContainer .cardContainer .card .cardText {
          font-size: $text-font-size-mobile;
          line-height: $text-line-height-mobile;
          margin-bottom: $text-bottom-gap-mobile;
        }
      }