@import '../../../../scss/utils/variables';

.background {
    background-color: #f7f9fc;  
    padding: 5rem 0;         
    position: relative;

    .container {
        width: 80%;
        margin: 0 auto;
        max-width: 1400px;
        border-radius: 10px;
        display: flex;   
        align-items: center; 
        position: relative;
        z-index: 100;
        // gap: 10rem;
        background-color: #FEFEFE;
        border: solid 1px #cccc;

        @media (max-width: 1240px) {
            flex-direction: column;
            align-items: flex-start;
        }




        .col1 {
            flex: 1;
            padding: 5rem 1rem;
            display: flex;
            flex-direction: column;
            z-index: 200;
            justify-content: center;

            
            .header {
                font-family: $font-family-header;
                font-size: $header-font-size;
                font-weight: $header-font-weight;
                color: $header-color;
                margin-bottom: $header-bottom-gap;
            }
            
            .subHeader {
                font-family: $font-family-header;
                font-size: $subheader-font-size;
                font-weight: $subheader-font-weight;
                color: $blue-sub-header;
                margin-bottom: $subheader-bottom-gap;
            }
             
            .text {
                font-family: $font-family-header;
                font-size: $text-font-size;
                margin-bottom: $text-bottom-gap;
                color: #121214;
                max-width: 500px;
            }

            .col1Content {
                height: 100%;
            }
        }

        .col2 {
            flex: 1;
            background-color: $blue-sub-header;
            padding: 5rem 1rem;
            display: flex;
            justify-content: center;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
            margin-left: 5rem;

            @media (max-width: 1240px) {
                width: 100%;
                padding: 3rem 0;
                margin: 0;
                border-radius: 0 0 10px 10px;
            }

            .iconContainer {
                display: grid;              
                grid-template-columns: repeat(2, 1fr); 
                grid-template-rows: repeat(3, 1fr);  
                gap: 2rem;                 
                height: 100%;     
                
                @media (max-width: 1240px) {
                    grid-template-columns: repeat(3, 1fr); 
                    grid-template-rows: repeat(2, 1fr);  
                }

                @media (max-width: 768px) {
                    grid-template-columns: repeat(2, 1fr); 
                    grid-template-rows: repeat(2, 1fr);  
                }

                .iconItem {
                    background-color: white;
                    width: 100%;             
                    height: 125px;   
                    width: 125px;       
                    display: flex;          
                    flex-direction: column;   
                    justify-content: center;   
                    align-items: center;      
                    text-align: center;
                    border-radius: 12px;      // Rounded corners for a softer look
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Deeper shadow for depth
                    transition: transform 0.2s, box-shadow 0.2s; // Smooth transitions on hover

                    img {
                        margin-bottom: 1rem; 
                        width: 35px;
                    }


                    &:hover {
                        transform: scale(1.1);
                        box-shadow: 0 6px 12px rgba(255, 255, 255, 0.2); // Enhanced shadow on hover
                    }

                    h6 {
                        font-size: 30px;
                        margin-bottom: 0.5rem; 
                        color: $blue-sub-header;
                    }

                    p {
                        font-family: $font-family-header;
                        font-size: 12px;
                        font-weight: 600;
                        color: #000000;
                    }
                }
            }
        }
    }
}

.blueStrip {
    background-color: $blue-header;
    width: 100%;
    height: 200px;
    margin-top: -200px;
    z-index: 0;
    position: absolute;
}


// Tablet styles
@media (max-width: 1024px) {
    .background .container .col1 .header {
      font-size: $header-font-size-tablet;
      font-weight: $header-font-weight-tablet;
      margin-bottom: $header-bottom-gap-tablet;
    }
  
    .background .container .col1 .subHeader {
      font-size: $subheader-font-size-tablet;
      font-weight: $subheader-font-weight-tablet;
      margin-bottom: $subheader-bottom-gap-tablet;
    }
  
    .background .container .col1 .text {
      font-size: $text-font-size-tablet;
      line-height: $text-line-height-tablet;
      margin-bottom: $text-bottom-gap-tablet;
    }
  
  
  }
  
  // Mobile styles
  @media (max-width: 600px) {
    .background .container .col1 .header {
      font-size: $header-font-size-mobile;
      font-weight: $header-font-weight-mobile;
      margin-bottom: $header-bottom-gap-mobile;
    }
    .background .container .col1 .subHeader {
      font-size: $subheader-font-size-mobile;
      font-weight: $subheader-font-weight-mobile;
      margin-bottom: $subheader-bottom-gap-mobile;
    }
  
    .background .container .col1 .text {
      font-size: $text-font-size-mobile;
      line-height: $text-line-height-mobile;
      margin-bottom: $text-bottom-gap-mobile;
    }
  
  }