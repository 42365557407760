.vrImageContainer {
  width: clamp(350px, 60vw, 700px); /* Scales between 300px (min), 50% of viewport width, and 700px (max) */
  height: clamp(200px, 30vw, 450px); /* Scales between 200px (min), 30% of viewport width, and 450px (max) */
  overflow: hidden;
  position: relative;

  @media (max-width: 400px) {
    width: clamp(300px, 55vw, 600px);
  }




  canvas {
    width: 100%; /* Ensures the canvas stretches to the container's width */
    height: 100%; /* Ensures the canvas stretches to the container's height */
    max-width: 100%;

  }
}
