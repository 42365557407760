@import '../../../scss/utils/variables';

.background {
  background-color: #f7f9fc; // Light background for contrast
  padding: 5rem 0;   
  
  @media (max-width: 768px) {
    padding: 5rem 0rem 0rem 0rem;
  }

  .container {
    width: 80%;
    margin: 0 auto;
    max-width: 1400px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap; // Enable wrapping for responsiveness
    padding: 0;
    .col1 {
      flex: 1;
      padding: 3rem 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 1024px) {
        padding: 0rem 0rem;
      }

      .col1Content {
        height: 100%;

        .header {
          font-family: $font-family-header;
          font-size: 32px;
          font-weight: 600;
          color: $blue-header;
          margin-bottom: 0.5rem;
        }

        .subHeader {
          font-family: $font-family-header;
          font-size: 24px;
          font-weight: 400;
          color: $blue-sub-header;
          margin-bottom: 1rem;
        }

        .text {
          font-family: $font-family-header;
          font-size: 16px;
          color: #121214;
          max-width: 500px;
        }
      }
    }

    .col2 {
      flex: 1;
      // background-color: $blue-sub-header;
      padding: 3rem .5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;

      .itemContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr); // 3 columns
        grid-template-rows: 1fr 1fr;           // 2 rows
        gap: 2rem;                             // Space between items
        width: 100%;
        justify-items: center;                 // Center items horizontally
        align-items: center;                   // Center items vertically

        @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr); // 2 columns
            grid-template-rows: repeat(3, 1fr);    // 3 rows
            gap: 1rem;    
        }

        .item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          background-color: white;
          border-radius: 12px;
          padding: 1rem;
          width: calc(100% - 2rem); // Ensure spacing between items
          height: 125px;
          margin: 0.5rem;           // Add space around each item
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          transition: transform 0.2s, box-shadow 0.2s;

          &:hover {
            transform: scale(1.05);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
          }

          h6 {
            font-size: 30px;
            color: #0D3C56;
            margin-bottom: 0.5rem;

            @media (max-width: 1024px) {
              font-size: 25px;
            }
            
            @media (max-width: 768px) {
              font-size: 23px;
            }
          }

          p {
            font-family: $font-family-header;
            font-size: 14px;
            font-weight: 600;
            color: #000000;

            @media (max-width: 1024px) {
              font-size: 13px;
            }
            
            @media (max-width: 768px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

// Responsive styles
@media (max-width: 1024px) {
  .container {
    flex-direction: column;
    align-items: center;

    .col1, .col2 {
      width: 100%;
      text-align: left;
    }

    .col1 {
      margin-bottom: 0rem;
    }

    .col2 {
      .itemContainer {
        grid-template-columns: repeat(2, 1fr); // 2 columns
        grid-template-rows: auto;             
        gap: 1.5rem;                         
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding: 2rem 1rem;
    flex-wrap: wrap;

    .col1Content {
      .header {
      }

      .subHeader {
      }

      .text {
        max-width: 100%;
      }
    }


    .col2 {
      .itemContainer {
        grid-template-columns: repeat(2, 1fr); // 2 columns
        grid-template-rows: repeat(3, 1fr);    // 3 rows
        gap: 1rem;                            // Reduced gap for small screens
      }

      .item {
        width: 100%;
        // padding: 1rem;
        margin: 0.5rem; // Space around each item

      }
    }
  }
}




// Tablet styles
@media (max-width: 1024px) {
  .background .container .col1 .col1Content .header {
    font-size: $header-font-size-tablet;
    font-weight: $header-font-weight-tablet;
    margin-bottom: $header-bottom-gap-tablet;
  }

  .background .container .col1 .col1Content .subHeader  {
    font-size: $subheader-font-size-tablet;
    font-weight: $subheader-font-weight-tablet;
    margin-bottom: $subheader-bottom-gap-tablet;
  }

  .background .container .col1 .col1Content .text  {
    font-size: $text-font-size-tablet;
    line-height: $text-line-height-tablet;
    margin-bottom: $text-bottom-gap-tablet;
  }


}

// Mobile styles
@media (max-width: 600px) {
  .background .container .col1 .col1Content .header {
    font-size: $header-font-size-mobile;
    font-weight: $header-font-weight-mobile;
    margin-bottom: $header-bottom-gap-mobile;
  }
  .background .container .col1 .col1Content  .subHeader  {
    font-size: $subheader-font-size-mobile;
    font-weight: $subheader-font-weight-mobile;
    margin-bottom: $subheader-bottom-gap-mobile;
  }

  .background .container .col1 .col1Content .text {
    font-size: $text-font-size-mobile;
    line-height: $text-line-height-mobile;
    margin-bottom: $text-bottom-gap-mobile;
  }

}