@import "../../scss/utils/variables";

.container {
  width: 80%;
  margin: 0 auto;
  max-width: 1400px;
}
.dedicatedServersContainer {
  width: 100%;
  max-width: 1400px;
  margin: 0rem auto;
  height: 100%;
  color: white;
  background-color: $blue-tint;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap-reverse;
  margin-top: 10rem;
  margin-bottom: 10rem;

  @media (max-width: 700px) {
    margin-top: 5rem;
    margin-bottom: 5rem;
    flex-direction: row-reverse;
  }

  &.reverse {
    flex-direction: row-reverse;
    gap: 10rem;

    
    @media (max-width: 700px) {
      flex-direction: row;
      gap: 0rem;    
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    width: 90%;
  }

  .dedicatedServersLeft {
    flex: 1;
    // padding: 3rem;

    .dedicatedServersHeader {
      font-family: $font-family-header;
      font-size: $header-font-size;
      font-weight: $header-font-weight;
      color: $header-color;
      margin-bottom: $header-bottom-gap;
    }

    .dedicatedServersSubHeader {
      font-family: $font-family-header;
      font-size: $subheader-font-size;
      font-weight: $subheader-font-weight;
      color: $blue-sub-header;
      margin-bottom: $subheader-bottom-gap;
    }

    .dedicatedServersText {
      font-family: $font-family-header;
      font-size: $text-font-size;
      color: #000000;
      margin-bottom: $text-bottom-gap;
      width: 70%;
      line-height: $text-line-height;
    }

    .dedicatedServersButton {
      display: inline-block;
      padding: 0.6rem 1.5rem;
      border: 0.16rem solid $blue-sub-header;
      color: $blue-sub-header;
      box-sizing: border-box;
      text-transform: uppercase;
      font-family: $font-family-header;
      font-weight: 400;
      text-align: center;
      transition: all 0.15s;
    }

    a {
      display: inline-block;
      padding: 1rem 3.5rem;
      border: 0.16rem solid $blue-sub-header;
      color: $blue-sub-header;
      box-sizing: border-box;
      text-transform: uppercase;
      font-family: $font-family-header;
      font-weight: 400;
      text-align: center;
      transition: all 0.15s ease;
      cursor: pointer;
      position: relative;
      z-index: 2;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);

      @media (max-width: 700px) {
        padding: .75rem 2rem;
      }
    

      &:hover {
        color: lighten($blue-sub-header, 20%); // Lighten color on hover
        border-color: lighten(
          $blue-sub-header,
          20%
        ); // Lighten border color on hover
      }

      &:active {
        color: lighten($blue-sub-header, 35%); // Lighter shade on active state
        border-color: lighten(
          $blue-sub-header,
          35%
        ); // Lighter border on active
      }
    }
  }

  .standardServer {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0);
    height: 550px;
    flex: 1;
    width: 100%;

    @media (max-width: 1024px) {
      height: 400px;
    }

    @media (max-width: 700px) {
      // display: none;
      // width: 50%;
      height: 30%;
      width: 130px;
    }
  }
}

@media (max-width: 761px) {
  .container {
    width: 100%;
    .dedicatedServersContainer {
      // justify-content: start;
      .dedicatedServersLeft {
        padding: 1rem;
        .dedicatedServersHeader {
        }

        .dedicatedServersSubHeader {
        }

        .dedicatedServersText {
          width: 100%;
        }
      }

      .serverBubble2 {
        max-width: 100px;
        flex: 1;
      }
    }
  }
}

// Tablet styles
@media (max-width: 1024px) {
  .container
    .dedicatedServersContainer
    .dedicatedServersLeft
    .dedicatedServersHeader {
    font-size: $header-font-size-tablet;
    font-weight: $header-font-weight-tablet;
    margin-bottom: $header-bottom-gap-tablet;
  }

  .container
    .dedicatedServersContainer
    .dedicatedServersLeft
    .dedicatedServersSubHeader {
    font-size: $subheader-font-size-tablet;
    font-weight: $subheader-font-weight-tablet;
    margin-bottom: $subheader-bottom-gap-tablet;
  }

  .container
    .dedicatedServersContainer
    .dedicatedServersLeft
    .dedicatedServersText {
    font-size: $text-font-size-tablet;
    line-height: $text-line-height-tablet;
    margin-bottom: $text-bottom-gap-tablet;
  }
}

// Mobile styles
@media (max-width: 600px) {
  .container
    .dedicatedServersContainer
    .dedicatedServersLeft
    .dedicatedServersHeader {
    font-size: $header-font-size-mobile;
    font-weight: $header-font-weight-mobile;
    margin-bottom: $header-bottom-gap-mobile;
  }

  .container
    .dedicatedServersContainer
    .dedicatedServersLeft
    .dedicatedServersSubHeader {
    font-size: $subheader-font-size-mobile;
    font-weight: $subheader-font-weight-mobile;
    margin-bottom: $subheader-bottom-gap-mobile;
  }

  .container
    .dedicatedServersContainer
    .dedicatedServersLeft
    .dedicatedServersText {
    font-size: $text-font-size-mobile;
    line-height: $text-line-height-mobile;
    margin-bottom: $text-bottom-gap-mobile;
  }
}

@media (max-width: 700px) {
  .hideOnMobile {
    display: none;
  }
}
