@import "../../../scss/utils/variables";



.fullWidthBackground {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: white;
  // box-shadow: 10px 10px 10px 10px rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  z-index: 1100; // Ensure this is above the overlay's z-index

  .container {
    background-color: rgb(255, 255, 255);
    width: 100%;
    max-width: 1400px;
    color: $black;
    padding: 1rem 1rem;

    .desktopNav {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 200px;
        height: 52px;

        @media (max-width: 700px) {
          width: 140px;
          height: 38px;
        }
      }

      .mainNav {
        @media (max-width: 1000px) {
          display: none;
        }

        .mainNavList {
          display: flex;
          gap: 3rem;
          align-items: center;
          list-style-type: none;

          .mainNavListItem {
            position: relative;

            .dropdownIndicator {
              margin-left: 5px;
              width: 12px;
              height: 12px;
              vertical-align: middle;
            }
            
            .downArrow {
              width: 18px;
              height: 18px;
              transition: transform 0.3s;
            }
            
            .rotated {
              transform: rotate(180deg);
            }

            .mainNavListItemLink {
              color: #2e2e2e;
              font-family: $font-family-header;
              text-transform: uppercase;
              font-size: 16px;
              font-weight: 600;
              text-decoration: none;
              padding: 2rem 0;
              transition: color 0.3s, border-bottom 0.3s;

              &:hover,
              &.active {
                // Apply same styles for hover and active
                color: $blue-header;
              }

              &::after {
                content: "";
                position: absolute;
                bottom: -5px;
                left: 0;
                right: 0;
                height: 1px;
                background-color: $blue-header;
                transform: scaleX(0);
                transition: transform 0.3s;
                transform-origin: center;
              }

              &:hover::after,
              &.active::after {
                // Show underline on hover and active
                // transform: scaleX(1);
              }
            }

            .dropdownMenu {
              position: absolute;
              left: 0;
              top: calc(100% + 32px); // Add space between the menu item and dropdown
              background-color: white;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
              padding: .5rem 2rem 0rem 0rem;
              z-index: 20;
              // border-radius:  10px;

              // border-radius: 4px;
              display: none; // Initially hidden
              min-width: 200px;
              list-style: none;

              &::before {
                content: "";
                position: absolute;
                top: -20px; // Expand the hoverable area
                left: 0;
                right: 0;
                height: 10px;
                background: transparent;
              }

              li {
                padding: 1rem 1rem;
                border-bottom: solid 1px #DDDD;

                &:first-child {
                  padding: 1rem 1rem 1rem 1rem;
                }

                a {
                  color: #2e2e2e;
                  font-family: $font-family-header;
                  text-transform: uppercase;
                  font-size: 16px;
                  font-weight: 600;
                  padding: 2rem 0;
                  transition: color 0.3s;

                  &:hover {
                    color: $blue-header;
                  }
                }
              }
            }

            &:hover .dropdownMenu {
              display: block; // Show on hover
            }
          }
        }
        &:hover .dropdownMenu {
          display: block; // Show on hover
        }
      
        .dropdownMenu:hover {
          display: block; // Keep dropdown visible if hovered
        }
      }

      .nav2 {
        @media (max-width: 1000px) {
          display: none;
        }
      
        .ul2 {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          list-style-type: none;
      
          img {
            width: 28px;
            height: 20px;
          }
      
          li {
            text-decoration: none;
      
            a {
              color: #2e2e2e; // default text color
              font-family: $font-family-header;
              text-transform: uppercase;
              font-size: 16px;
              font-weight: 600;
              padding: 5px 0;
              display: flex;
              align-items: center;
              border: 0.16rem solid $blue-sub-header;
              padding: 0.5rem 2rem;
              position: relative;
              transition: all 0.3s ease;
              z-index: 19;
      
              &:hover {
                color: #fff; // text color on hover (inverse)
                background-color: $blue-header; // background color on hover (inverse)
                border: 0.16rem solid white;
              }
      
              &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: $blue-header; // background color when hovered
                opacity: 0; // initially hidden
                transition: opacity 3s ease;
              }
      
              // &:hover::after {
                opacity: 1; // make the background appear on hover
              // }
            }
          }
        }
      }
      
      .hamburgerMenu {
        display: none;
        cursor: pointer;
        width: 30px;

        @media (max-width: 1000px) {
          display: block;
        }
      }
    }

    .mobileNav {
      @media (min-width: 1000px) {
        display: none;
      }

      background-color: white;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      position: absolute;
      width: 100%;
      top: 74px;
      left: 0;
      padding-top: 0px;
      z-index: 1000;

      .mobileNavList {
        list-style: none;
        padding: 0;
        text-align: left;

        li {
          padding: 1rem;

          a {
            color: black;
            text-decoration: none;
            font-weight: 400;
            color: #2e2e2e;
            font-family: $font-family-header;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }

      .mobileNavItemWithArrow {
        display: flex;
        align-items: center;
        // justify-content: center;
        gap: 0.5rem;
        cursor: pointer;

        .downArrow {
          width: 18px;
          height: 18px;
          transition: transform 0.3s;
        }

        .rotated {
          transform: rotate(180deg);
        }
      }

      .subMenuList {
        list-style: none;
        padding: 0;
        margin-top: 1rem;
        text-align: left;
        padding-left: 1.5rem;

        li {
          padding: 0.5rem 0;

          a {
            color: black;
            font-size: 1rem;
            text-decoration: none;
          }
        }
      }
    }
  }
}


.company {
  cursor: default;
}