@import '../../../../scss/utils/variables';

.container {
    width: 80%;
    margin: 0rem auto;
    max-width: 1400px;
    margin-top: 10rem;
    margin-bottom: 10rem;

    .header {
        font-family: $font-family-header;
        font-size: $header-font-size;
        font-weight: $header-font-weight;
        color: $header-color;
        margin-bottom: $header-bottom-gap;
        // text-align: center;
    }

    .subHeader {
        font-family: $font-family-header;
        font-size: $subheader-font-size;
        font-weight: $subheader-font-weight;
        color: $blue-sub-header;
        margin-bottom: $subheader-bottom-gap;
        // text-align: center;
    }

    .text {
        font-family: $font-family-header;
        font-size: $text-font-size;
        margin-bottom: $text-bottom-gap;
        color:  #121214;
        // text-align: center;
        width: 70%;

        @media (max-width: 1024px){
          width: 90%;
      }

      @media (max-width: 600px){
          width: 100%;
      }
    }

    .groupContainerBig {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(3, 1fr); // 3 columns of equal width
      grid-template-rows: repeat(3, auto); // 3 rows of auto height

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }
    
      .leftGroup {
        align-items: flex-start;
        padding: 2rem;
        border: solid 1px #f5f5f5;
        border-radius: 8px;
        border: solid 1px #e9e9e9;

        .icon {
          width: 30px;
          margin-bottom: 1rem;
          border-radius: 10px;
          padding: 1rem;
          background: linear-gradient(90deg, $blue-header, #0079a1, #07587F, #0098ca); /* Vibrant gradient */
          animation: gradientBorderAnimation 6s linear infinite; /* Smooth gradient movement */
          mask-composite: exclude;
          z-index: -1; /* Ensures the gradient stays behind the content */
          -webkit-mask-composite: destination-out;
          background-size: 400% 400%; /* Larger size for smooth animation */

        }

        @media (max-width: 1024px) {
          .icon {
            width: 35px;
          }
        }
  
        @media (max-width: 600px) {
          .icon {
            width: 30px;
          }
        }

        .groupHeader {
          text-align: left;
          font-family: $font-family-header;
          font-weight: 600;
          margin-bottom: 1rem;
        }

        @media (max-width: 1024px) {
          .groupHeader {
            font-size: 15px;
          }
        }

        @media (max-width: 600px) {
          .groupHeader {
            font-size: 12px;
          }
        }

        .groupText {
          text-align: left;
          font-family: $font-family-header;
          font-weight: 400;
          font-size: 14px;
        }

        @media (max-width: 1024px) {
          .groupText {
            font-size: 13px;
          }
        }

        @media (max-width: 600px) {
          .groupText {
            font-size: 13px;
          }
        }
      }

      @media (max-width: 1024px) {
        .leftGroup {
          padding: 1rem;
        }
      }

      @media (max-width: 600px) {
        .leftGroup {
          padding: 1rem;
        }
      }
    
      /* Specific grid area placements */
      .item1 {
        grid-area: 1 / 1 / 2 / 2; // Row 1, Column 1 (Top Left)
      }
      .item2 {
        grid-area: 1 / 2 / 2 / 3; // Row 1, Column 2 (Top Middle)
      }
      .item3 {
        grid-area: 1 / 3 / 3 / 4; // Row 1 to 2, Column 3 (Top Right + Middle Right)
      }
      .item4 {
        grid-area: 2 / 1 / 3 / 3; // Row 2, Columns 1-2 (Middle Left + Center)
      }
      .item5 {
        grid-area: 3 / 1 / 4 / 2; // Row 3, Column 1 (Bottom Left)
      }
      .item6 {
        grid-area: 3 / 2 / 4 / 4; // Row 3, Column 3 (Bottom Right)
      }
    }
      
}



// Tablet styles
@media (max-width: 1024px) {
  .container .header {
    font-size: $header-font-size-tablet;
    font-weight: $header-font-weight-tablet;
    margin-bottom: $header-bottom-gap-tablet;
  }

  .container .subHeader  {
    font-size: $subheader-font-size-tablet;
    font-weight: $subheader-font-weight-tablet;
    margin-bottom: $subheader-bottom-gap-tablet;
  }

  .container .text {
    font-size: $text-font-size-tablet;
    line-height: $text-line-height-tablet;
    margin-bottom: $text-bottom-gap-tablet;
  }


}

// Mobile styles
@media (max-width: 600px) {
  .container .header {
    font-size: $header-font-size-mobile;
    font-weight: $header-font-weight-mobile;
    margin-bottom: $header-bottom-gap-mobile;
  }

  .container .subHeader {
    font-size: $subheader-font-size-mobile;
    font-weight: $subheader-font-weight-mobile;
    margin-bottom: $subheader-bottom-gap-mobile;
  }

  .container .text {
    font-size: $text-font-size-mobile;
    line-height: $text-line-height-mobile;
    margin-bottom: $text-bottom-gap-mobile;
  }

}

    /* Keyframes for the gradient animation */
    @keyframes gradientBorderAnimation {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }