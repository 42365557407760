@import '../../../../scss/utils/variables';

.marginTop {
    margin-top: 2rem;
}

span {
    font-weight: 600;
    color: $blue-dark;
}

.background {
    font-family: $font-family-header;

    .container {
        display: flex;

        .col1 {
            flex: 3;
            background-color: #f7f9fc;
            padding: 5rem;

            @media (max-width: 1024px) {
                padding: 5rem 3rem;
            }

            @media (max-width: 700px) {
                padding: 4rem 2rem;
            }

            .header {
                font-family: $font-family-header;
                font-size: $header-font-size;
                font-weight: $header-font-weight;
                color: $header-color;
                margin-bottom: $header-bottom-gap;
            }

            .section {
                margin-top: 3rem;
                h3 {
                    font-family: $font-family-header;
                    font-size: $subheader-font-size;
                    font-weight: $subheader-font-weight;
                    color: $blue-sub-header;
                    margin-bottom: $subheader-bottom-gap;
                }

                p {
                    width: 80%;
                    font-family: $header-font-family;
                    font-size: $text-font-size;
                    color: #121214;
                    margin-bottom: $text-bottom-gap;

                    
                    @media (max-width: 1024px) {
                        width: 80%;
                    }
        
                    @media (max-width: 700px) {
                        width: 100%;
                    }
                }
                ul {
                    li {
                        margin: 1rem 0;
                        color: #121214;
                        width: 70%;

                        @media (max-width: 1024px) {
                            width: 80%;
                        }
            
                        @media (max-width: 700px) {
                            width: 100%;
                        }

                    }
                }
            }

        }
        
        .col2 {
            flex: 1;
            background-color: $blue-dark;
            padding: 5rem 1rem;

            @media (max-width: 1024px) {
                padding: 5rem .75rem;
            }

            @media (max-width: 700px) {
                padding: 4rem .5rem;
            }

            @media (max-width: 640px) {
                display: none;            
            }

            .list {
                margin-top: 4.5rem;
                position: sticky;
                top: 5rem;
                list-style: none;

                .listItem {
                    font-family: $header-font-family;
                    font-size: $text-font-size;
                    color: #ffffffad;
                    margin: 1rem 0 ;
                    cursor: pointer;

                    &.active {
                        color: white;
                    }
                }
            }
        }
    }
}



// Tablet styles
@media (max-width: 1024px) {
    .background .container .col1 .header {
      font-size: $header-font-size-tablet;
      font-weight: $header-font-weight-tablet;
      margin-bottom: $header-bottom-gap-tablet;
    }
  
    .background .container .col1 .section h3  {
      font-size: $subheader-font-size-tablet;
      font-weight: $subheader-font-weight-tablet;
      margin-bottom: $subheader-bottom-gap-tablet;
    }
  
    .background .container .col1 .section p,
    .background .container .col1 .section ul li,
    .background .container .col2 .list .listItem {
      font-size: $text-font-size-tablet;
      line-height: $text-line-height-tablet;
      margin-bottom: $text-bottom-gap-tablet;
    }
  
  
  }
  
  // Mobile styles
  @media (max-width: 700px) {
    .background .container .col1 .header  {
      font-size: $header-font-size-mobile;
      font-weight: $header-font-weight-mobile;
      margin-bottom: $header-bottom-gap-mobile;
    }
    .background .container .col1 .section h3 {
      font-size: $subheader-font-size-mobile;
      font-weight: $subheader-font-weight-mobile;
      margin-bottom: $subheader-bottom-gap-mobile;
    }
  
    .background .container .col1 .section p,
    .background .container .col1 .section ul li,
    .background .container .col2 .list .listItem  {
      font-size: $text-font-size-mobile;
      line-height: $text-line-height-mobile;
      margin-bottom: $text-bottom-gap-mobile;
    }
  
  }