@import "../../../../scss/utils/variables";

.background {
  background-color: #f5f5f5;
  padding-top: 10rem;
  padding-bottom: 10rem;

  @media (max-width: 768px) {
    padding-top: 5rem;
    padding-bottom: 0rem;
  }
}

.dedicatedServersContainer {
  width: 80%;
  max-width: 1400px;
  margin: 0 auto;
  // margin-top: 10rem;
  color: #121214;
  background-color: #f5f5f5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  .dedicatedServersLeft {
    flex: 1;


    .dedicatedServersHeader {
      font-family: $font-family-header;
      font-size: $header-font-size;
      font-weight: $header-font-weight;
      color: $header-color;
      margin-bottom: $header-bottom-gap;
    }

    .dedicatedServersSubHeader {
      font-family: $font-family-header;
      font-size: $subheader-font-size;
      font-weight: $subheader-font-weight;
      color: $blue-sub-header;
      margin-bottom: $subheader-bottom-gap;
    }

    .dedicatedServersText {
      font-family: $font-family-header;
      font-size: $text-font-size;
      margin-bottom: $text-bottom-gap;
    }

    .dedicatedServersButton {
      display: inline-block;
      padding: 0.6rem 1.5rem;
      border: 0.16rem solid $blue-sub-header;
      color: $blue-sub-header;
      text-transform: uppercase;
      font-family: $font-family-header;
      font-weight: 400;
      text-align: center;
      margin-top: 2rem;
    }
  }

  .statesContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    img {
      max-width: 80%;
      height: auto;
      cursor: pointer;

      &.activeImage {
        // border: 2px solid $white;
        box-shadow: 12px 12px 12px 0px rgba(0, 0, 0, 0.219);
        border-radius: 5px;
      }
    }
  }
}

// Responsive styling
@media (max-width: 1024px) {
  .dedicatedServersContainer {
    flex-direction: column-reverse;
    align-items: start;
    padding: 2rem;

    .dedicatedServersLeft {
      padding: 3rem 1rem;

      .dedicatedServersButton {
        padding: 0.5rem 1rem;
        font-size: 14px;
      }
    }

    .statesContainer {
      display: flex;
      align-items: start;
      img {
        max-width: 80%;
      }
    }
  }
}

@media (max-width: 768px) {
  .dedicatedServersContainer {
    padding: 0;

    .dedicatedServersLeft {
    }

    .statesContainer {
      img {
        max-width: 60%;
      }
    }
  }
}

@media (max-width: 480px) {
  .dedicatedServersContainer {
    .statesContainer {
      img {
        max-width: 50%;
      }
    }
  }
}

// Tablet styles
@media (max-width: 1024px) {
  .background
    .dedicatedServersContainer
    .dedicatedServersLeft
    .dedicatedServersHeader {
    font-size: $header-font-size-tablet;
    font-weight: $header-font-weight-tablet;
    margin-bottom: $header-bottom-gap-tablet;
  }

  .background
    .dedicatedServersContainer
    .dedicatedServersLeft
    .dedicatedServersSubHeader {
    font-size: $subheader-font-size-tablet;
    font-weight: $subheader-font-weight-tablet;
    margin-bottom: $subheader-bottom-gap-tablet;
  }

  .background
    .dedicatedServersContainer
    .dedicatedServersLeft
    .dedicatedServersText {
    font-size: $text-font-size-tablet;
    line-height: $text-line-height-tablet;
    margin-bottom: $text-bottom-gap-tablet;
  }
}

// Mobile styles
@media (max-width: 600px) {
  .background
    .dedicatedServersContainer
    .dedicatedServersLeft
    .dedicatedServersHeader {
    font-size: $header-font-size-mobile;
    font-weight: $header-font-weight-mobile;
    margin-bottom: $header-bottom-gap-mobile;
  }

  .background
    .dedicatedServersContainer
    .dedicatedServersLeft
    .dedicatedServersSubHeader {
    font-size: $subheader-font-size-mobile;
    font-weight: $subheader-font-weight-mobile;
    margin-bottom: $subheader-bottom-gap-mobile;
  }

  .background
    .dedicatedServersContainer
    .dedicatedServersLeft
    .dedicatedServersText {
    font-size: $text-font-size-mobile;
    line-height: $text-line-height-mobile;
    margin-bottom: $text-bottom-gap-mobile;
  }
}

.dedicatedServersText {
  font-family: $font-family-header;
  font-size: $text-font-size;
  margin-bottom: $text-bottom-gap;
  line-height: $text-line-height;
}
