@import '../../../../scss/utils/variables';

/* Background styles */
.background {
  background-color: #f7f9fc;
  width: 100%;
  height: 100%;
}

/* Container styles */
.container {
  max-width: 80%;
  margin: 0 auto;
  padding: 5rem 0;

  .tableContainer {
    margin-bottom: 1em;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;

    &.open {
        border-bottom: none; // Remove bottom border when open
      }
  }

  .titleRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
    cursor: pointer;
    transition: background-color 0.2s ease; // Smooth background change on hover

  }

  .title {
    font-family: $font-family-header;
    color: $blue-dark;

    @media (max-width: 1024px) {
        font-size: 16px;    
    }

    @media (max-width: 700px) {
        font-size: 14px;    
    }
  }

  .indicator {
    font-size: 1.2rem;
    transition: transform 0.2s ease;
  }

  .note {
    padding: 10px 10px;
    font-family: $font-family-header;
    font-size: $text-font-size;
    color: black;
    line-height: 1.3;
    margin-bottom: 1rem;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  .headerCell,
  .cell {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    text-align: center;
    vertical-align: middle;
  }
}

/* Header styles */
.header {
    font-family: $font-family-header;
    font-size: $header-font-size;
    font-weight: $header-font-weight;
    color: $header-color;
    margin-bottom: $header-bottom-gap;
}

/* Subheader styles */
.subHeader {
    font-family: $font-family-header;
    font-size: $subheader-font-size;
    font-weight: $subheader-font-weight;
    color: $blue-sub-header;
    margin-bottom: $subheader-bottom-gap;
}

/* Text styles */
.text {
    font-family: $font-family-header;
    font-size: $text-font-size;
    margin-bottom: $text-bottom-gap;
    color: black;
    line-height: 1.3;
    max-width: 70%;

    @media (max-width: 1024px) {
        max-width: 80%;
    }

    @media (max-width: 700px) {
        max-width: 100%;
    }

    span {
        font-weight: 600;
    }
}

/* Tablet styles */
@media (max-width: 1024px) {
    .header {
        font-size: $header-font-size-tablet;
        font-weight: $header-font-weight-tablet;
        margin-bottom: $header-bottom-gap-tablet;
    }
    
    .subHeader {
        font-size: $subheader-font-size-tablet;
        font-weight: $subheader-font-weight-tablet;
        margin-bottom: $subheader-bottom-gap-tablet;
    }
    
    .text  {
        font-size: $text-font-size-tablet;
        line-height: $text-line-height-tablet;
        margin-bottom: $text-bottom-gap-tablet;
    }
}

/* Mobile styles */
@media (max-width: 700px) {
    .header {
        font-size: $header-font-size-mobile;
        font-weight: $header-font-weight-mobile;
        margin-bottom: $header-bottom-gap-mobile;
    }
    
    .subHeader {
        font-size: $subheader-font-size-mobile;
        font-weight: $subheader-font-weight-mobile;
        margin-bottom: $subheader-bottom-gap-mobile;
    }
    
    .text {
        font-size: $text-font-size-mobile;
        line-height: $text-line-height-mobile;
        margin-bottom: $text-bottom-gap-mobile;
    }
}
