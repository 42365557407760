@import '../../scss/utils/variables';

.container {
    width: 100%;
    margin: 0 auto;
    background-color: $blue-dark;
    color: white;
    display: flex;
    gap: 1rem;
    padding: 6rem 0rem;

    .col1 {
        flex: 1;
        padding: 0rem 1rem;

        @media (max-width: 1024px) {
            padding: 0rem .5rem;
          }

          @media (max-width: 600px) {
            padding: 0rem 0rem;
          }

        h4 {
            font-family: $font-family-header;
            font-size: 18px;
            font-weight: 400;
            margin: 1.5rem 0;
            padding: 0rem 1rem;

            @media (max-width: 1024px) {
                font-size: 16px;
            }

            @media (max-width: 600px) {
                font-size: 14px;
            }
        }

        .filterOptionsContainer {
            color: #F7F7F7;

            .filterItem {
                margin: .5rem 0;
                .filterOptionsItemClosed {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 1rem;
                    padding: 1rem 1rem;
                    cursor: pointer;
                    transition: 
                        background-color 0.3s ease,
                        border-top-left-radius 0.3s ease, 
                        border-top-right-radius 0.3s ease; 


                    
                    &.active {
                        background-color: $blue-sub-header;
                        border-top-left-radius: 8px; 
                        border-top-right-radius: 8px; 
                    }

                    p {
                        text-transform: uppercase;
                        font-size: 14px;
                        font-weight: 500;

                        @media (max-width: 1024px) {
                            font-size: 12px;
                        }
            
                        @media (max-width: 600px) {
                            font-size: 11px;
                        }
                    }
    
                    img {
                        width: 16px;
                        height: 16px;
                        transition: transform 0.3s ease;
                    }
                }
            
                .filterOptionsItemOpen {
                    color: #121214;
                    background-color: white;
                    overflow: hidden;
                    max-height: 0;
                    padding: 0;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    transition: max-height 0.3s ease, padding 0.3s ease, border-radius 0.3s ease;
          
                    &.expanded {
                      max-height: 200px; 
                      padding: 1rem; 
                      border-bottom-left-radius: 5px;
                      border-bottom-right-radius: 5px;
                    }
                    
                    ul {
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        
                        .dropdownItem {
                            font-size: 12px;
                            margin: .5rem 0;

                            @media (max-width: 1024px) {
                                font-size: 10px;
                            }
                
                            @media (max-width: 600px) {
                                font-size: 9px;
                            }

                            label {
                                display: flex;
                                gap: .5rem;
                                align-items: center;
                                font-size: 14px;
                                color: $blue-dark;
                                cursor: pointer;

                                @media (max-width: 1024px) {
                                    font-size: 11px;
                                }
                    
                                @media (max-width: 600px) {
                                    font-size: 10px;
                                }
                                
                                input[type="checkbox"] {
                                    appearance: none;
                                    width: 18px;
                                    height: 18px;
                                    border: 1px solid $blue-sub-header;
                                    border-radius: 3px;
                                    background-color: white;
                                    cursor: pointer;
                                    transition: all 0.2s ease;

                                    @media (max-width: 1024px) {
                                      width: 16px;
                                      height: 16px;
                                    }
                        
                                    @media (max-width: 600px) {
                                      width: 14px;
                                      height: 14px;
                                    }
                  
                                    &:checked {
                                      background-color: $blue-sub-header;
                                      border-color: $blue-sub-header;
                                      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3E%3Cpath fill="%23FFF" d="M6.293 9.293L2.293 5.293 3.707 3.879 7.5 7.671l5.5-5.5L14.914 3l-6.414 6.414z"/%3E%3C/svg%3E');
                                      background-position: center;
                                      background-repeat: no-repeat;
                                    }
                  
                                    &:hover {
                                      border-color: darken($blue-sub-header, 10%);
                                    }
                                  }
                            }
                        }
                    }
                }

            }

        }
    }

    .col2 {
        flex: 7;
        color: black;
        // background-color: white;
        background-color: #f7f7f7;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        padding: 1.5rem;

        

        .activeFiltersTitle {
            font-family: $font-family-header;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 2rem;

            @media (max-width: 1024px) {
                font-size: 18px;
            }

            @media (max-width: 600px) {
                font-size: 16px;
            }
        }

        .activeFiltersContainer {
            display: flex;
            justify-content: space-between;
            align-items: start;
            gap: 1rem;

            .activeFilters {
                display: flex;
                gap: 2rem;
                margin-bottom: .5rem;
                // color: $blue-sub-header;
                font-weight: 500;
                font-size: 18px;

                @media (max-width: 1024px) {
                    font-size: 16px;
                }
    
                @media (max-width: 600px) {
                    font-size: 14px;
                }

                .activeFilterGroup {
                    display: flex;
                    flex-direction: column;
                    gap: .25rem;

                    .activeFilterHeader {
                        font-weight: bold;
                        margin-bottom: 5px;
                    }

                    .activeFilter {
                        display: inline-block;
                        margin-right: 10px;
                        padding: 5px;
                        background-color: #f0f0f0;
                        border-radius: 5px;
                    }
                }
            }

            .clearAll {
                color: $blue-sub-header;
                font-weight: 500;
                font-size: 18px;

                @media (max-width: 1024px) {
                    font-size: 16px;
                }
    
                @media (max-width: 600px) {
                    font-size: 14px;
                }
                cursor: pointer;
            }
        }

        hr {

        }

        .tableContainer {
            width: 100%;
            margin: 1rem 0;
            overflow-x: auto; // Enables scrolling when necessary
            display: block; // Prevents flexbox from stretching the container
          
            // Header Row
            .flexHeader {
              display: flex;
            background-color: $blue-dark;
            color: white;


              align-items: center;
              padding: 1rem 0;
              font-size: 16px;
              font-weight: bold;
            
          
              @media (max-width: 1024px) {
                font-size: 14px;
              }
              @media (max-width: 600px) {
                font-size: 13px;
              }

              transition: box-shadow 0.3s ease;
              &:hover {
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.014); // increased shadow on hover
              }
            }
            
            .darker {
                font-weight: 700 !important;
            }
            // Data Row
            .flexRow {
              display: flex;
              align-items: center;
              padding: .5rem 0;
              margin: 1rem 0;

            

              font-size: 14px;
              font-family: $font-family-header;
              font-weight: 500;

              background-color: white;

              border: 1px solid #D6D6D6;
              border-radius: 5px;

              box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1); // subtle drop shadow

          
              @media (max-width: 1024px) {
                font-size: 13px;
              }
              @media (max-width: 600px) {
                font-size: 12px;
              }
            }
          
            // Each Cell in Header and Row
            .flexCell {
              flex: 1;
              padding: 0 1rem;
              width: 100%;
              text-align: left;

              @media (max-width: 1024px) {
                padding: 0 .75rem;
            }
              @media (max-width: 600px) {
                padding: 0 .5rem;
              }
            }

            .flexHeader, .flexRow {
              display: flex;
              width: 100%;
              min-width: 600px; // Ensures that the table doesn't shrink too much 
            }
          
            // Price container can remain mostly unchanged
            .priceContainer {
              display: flex;
              gap: .25rem;
              flex-direction: column;
            }
          
            .orderNowButton {
              /* Your order now button styling here */
              margin-bottom: 0.5rem;
            }
          
            // Loader, Error and No Data Rows
            .loaderRow,
            .errorRow,
            .noDataRow {
              padding: 1rem;
              text-align: center;
            }
          }
          

        @media (max-width: 768px) {
            overflow-x: auto; // Enable horizontal scroll on smaller screens
            -webkit-overflow-scrolling: touch; // Smooth scrolling for iOS
    
            .table {
                min-width: 600px; // Set a minimum width to trigger horizontal scroll on mobile
            }
        }
    }
}



.priceContainer {
    display: flex;
    align-items: center;        
    justify-content: center;    
    flex-wrap: wrap;            
    gap: 1rem;                  
    margin-top: 0.5rem;

    a:first-child {
        font-size: 16px;
        font-weight: bold;
        color: $blue-dark;      
        text-decoration: none;  

        @media (max-width: 1024px) {
            font-size: 14px;
        }

        @media (max-width: 600px) {
            font-size: 12px;
        }
    }

    .orderNowButton {
        display: inline-block;
        padding: 0.5rem 2rem;         
        border: 0.16rem solid $blue-sub-header;
        color: $blue-dark;
        box-sizing: border-box;
        text-transform: uppercase;
        font-family: $font-family-header;
        font-size: 12px;          
        font-weight: 500;
        text-align: center;
        transition: all 0.15s ease;
        cursor: pointer;
        background-color: white;   
        border-radius: 4px;       
        
        @media (max-width: 1024px) {
            font-size: 11px;
        }

        @media (max-width: 600px) {
            font-size: 11px;
        }
      
        &:hover {
          color: #DDDDDD;
          border-color: #DDDDDD;
          background-color: $blue-sub-header;
        }
      
        &:active {
          color: #BBBBBB;
          border-color: #BBBBBB;
        }
    }
}


