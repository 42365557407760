@import '../../../../scss/utils/variables';

  
    .container {
      width: 95%;
      margin: 0 auto;
      max-width: 1400px;
      // padding: 0 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .formContainer {
        background-color: #fff;
        padding: 2rem;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 100%;
        // max-width: 800px;
      }
  
      .header {
        font-family: $font-family-header;
        font-size: 32px;
        font-weight: 600;
        color: $blue-header;
        margin-bottom: 1rem;
      }
  
      .text {
        font-family: $font-family-header;
        font-size: 16px;
        color: #121214;
        margin-bottom: 2rem;
      }
  
      .errorText {
        color: red;
        font-size: 14px;
        margin: 0.5rem 0;
      }
  
      .successText {
        color: green;
        font-size: 14px;
        margin: 0.5rem 0;
      }
  
      .quoteForm {
        display: flex;
        flex-direction: column;
  
        .section {
          h3 {
            color: $blue-sub-header;
            font-family: $font-family-header;
            font-size: 18px;
            margin-bottom: .5rem;
          }
  
          .formGroup {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
  
            label {
              font-size: 14px;
              color: #121214;
              opacity: 0.6; // Low opacity for a subtle look
              margin-bottom: 0.25rem; // Slight space between label and input
            }
  
            input,
            select,
            textarea {
              padding: 0.75rem 1rem;
              border: 1px solid #e0e0e0;
              border-radius: 4px;
              font-size: 16px;
              font-family: $font-family-header;
              background-color: #f5f5f5;
              color: #121214;
              outline: none;
              transition: border-color 0.2s ease-in-out;
  
              &:focus {
                border-color: $blue-header;
              }
  
              &::placeholder {
                color: #b0b0b0;
              }
            }
  
            select {
              appearance: none;
              background: url('../../../../Assets/icons/Down-Black.svg') no-repeat right 1rem center;
              background-size: 12px;
            }
  
            textarea {
              resize: none;
              height: 80px;
            }
          }
  
          .formGroupCheckbox {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
  
            input[type="checkbox"] {
              width: 20px;
              height: 20px;
              margin-right: 0.5rem;
            }
  
            label {
              font-size: 16px;
              font-family: $font-family-header;
              color: #12121409;
            }
          }
        }
  
        .grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 1rem;
  
          @media (max-width: 768px) {
            grid-template-columns: 1fr;
          }
        }
  
        .sendButton {
          background-color: $blue-header;
          color: #fff;
          font-family: $font-family-header;
          font-size: 16px;
          padding: 1rem;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          text-transform: uppercase;
          transition: background-color 0.3s ease;
  
          &:hover {
            background-color: darken($blue-header, 5%);
          }
  
          &:active {
            background-color: darken($blue-header, 10%);
          }
  
          @media (max-width: 1024px) {
            font-size: 14px;
          }
  
          @media (max-width: 600px) {
            font-size: 12px;
          }
        }
      }
    }

  
  // Tablet styles
  @media (max-width: 1024px) {
    .background {
      .container {
        max-width: 100%;
  
        .formContainer {
          padding: 1.5rem;
        }
  
        .header {
          font-size: 28px;
        }
  
        .text {
          font-size: 14px;
        }
  
        .quoteForm {
          .formGroup {
            input,
            select,
            textarea {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  
  // Mobile styles
  @media (max-width: 600px) {
    .background {
        padding: 0rem 0rem;
      .container {
        width: 100%;

        .formContainer {
            width: 100%;
        }
  
        .header {
          font-size: 24px;
        }
  
        .text {
          font-size: 12px;
        }
  
        .quoteForm {
          .formGroup {
            input,
            select,
            textarea {
              font-size: 12px;
            }
          }
  
          .sendButton {
            font-size: 14px;
          }
        }
      }
    }
  }
  
  .lottieTopRight {
    position: absolute;
    top: 1400px;
    left: 50%;
    width: 500px; /* Explicit width */
    height: 200px; /* Explicit height */
    z-index: 100;
    transform: translate(-50%, -50%);
}
