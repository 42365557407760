@import '../../../scss/utils/variables';

.background {
    background-color: #f7f9fc;
    width: 100%;
}

.container {
    width: 80%;
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); // Adjust for responsive wrapping
    gap: 2rem;
    padding: 5rem 0;
    text-align: left;

    .col1, .col2, .col3 {
        align-self: flex-start;
    }

    .col1 {
        img {
            width: 200px;
        }
        
        .text {
            font-family: $font-family-header;
            font-size: $text-font-size;
            color: #121214;
            margin-top: 0.5rem;
            width: 80%;

            @media (max-width: 1024px) {
                font-size: $text-font-size-tablet;
            }

            @media (max-width: 600px) {
                font-size: $text-font-size-mobile;
            }
        }
    }

    .col2 {
        h4 {
            font-family: $font-family-header;
            font-size: $header-font-size;
            font-weight: $header-font-weight;
            color: $header-color;
            margin-bottom: $header-bottom-gap;

            @media (max-width: 1024px) {
                font-size: $header-font-size-tablet;
            }

            @media (max-width: 600px) {
                font-size: $header-font-size-mobile;
            }
        }

        ul {
            padding: 0;
            list-style-type: none;

            li {
                font-family: $font-family-header;
                font-size: $text-font-size;
                margin-bottom: $text-bottom-gap;

                a {
                    color: #121214;
                    text-decoration: none;

                    @media (max-width: 1024px) {
                        font-size: $text-font-size-tablet;
                    }
    
                    @media (max-width: 600px) {
                        font-size: $text-font-size-mobile;
                    }
                    
                    &:hover {
                        color: $blue-header; // Add hover effect for links
                    }
                }
            }
        }
    }

    .col3 {
        h4 {
            font-family: $font-family-header;
            font-size: 28px;
            color: $blue-header;
            margin-bottom: 2rem;

            @media (max-width: 1024px) {
                font-size: $header-font-size-tablet;
            }

            @media (max-width: 600px) {
                font-size: $header-font-size-mobile;
            }
        }

        div {
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            margin-bottom: 1.5rem;

            img {
                width: 25px;

                @media (max-width: 1024px) {
                    width: 20px;
                }

                @media (max-width: 600px) {
                    width: 15px;
                }
            }

            p {
                font-family: $font-family-header;
                font-size: $text-font-size;
                margin: 0;

                @media (max-width: 1024px) {
                    font-size: $text-font-size-tablet;
                }

                @media (max-width: 600px) {
                    font-size: $text-font-size-mobile;
                }
            }
        }
    }


// Tablet styles
@media (max-width: 1024px) {
    .background .container .col2 h4,
    .background .container .col2 .group2 .header {
      font-size: $header-font-size-tablet;
      font-weight: $header-font-weight-tablet;
      margin-bottom: $header-bottom-gap-tablet;
      margin-bottom: .5rem;
    }
  
    .background .container .col1 .col1Content .subHeader,
    .background .container .col2 .group2 .contactGrid .contactGroup .subHeader  {
      font-size: $subheader-font-size-tablet;
      font-weight: $subheader-font-weight-tablet;
      margin-bottom: $subheader-bottom-gap-tablet;
    }
  
    .background .container .col2 .group1 .text,
    .background .container .col2 .group2 .contactGrid .contactGroup .emailGroup p,
    .background .container .col2 .group2 .contactGrid .contactGroup .phoneGroup p  {
      font-size: $text-font-size-tablet;
      line-height: $text-line-height-tablet;
      margin-bottom: $text-bottom-gap-tablet;
    }
  
  
  }
  
  // Mobile styles
  @media (max-width: 600px) {
    .background .container .col2 h4,
    .background .container .col2 .group2 .header  {
      font-size: $header-font-size-mobile;
      font-weight: $header-font-weight-mobile;
      margin-bottom: $header-bottom-gap-mobile;
    }
    .background .container .col1 .col1Content  .subHeader,
    .background .container .col2 .group2 .contactGrid .contactGroup .subHeader  {
      font-size: $subheader-font-size-mobile;
      font-weight: $subheader-font-weight-mobile;
      margin-bottom: $subheader-bottom-gap-mobile;
    }
  
    .background .container .col2 .group1 .text  {
      font-size: $text-font-size-mobile;
      line-height: $text-line-height-mobile;
      margin-bottom: $text-bottom-gap-mobile;
      margin-bottom: .5rem;
  
    }
  
  }
}
